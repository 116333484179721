// import swRuntime from 'serviceworker-webpack-plugin/lib/runtime';

import '../assets/images/favicon.png';
import '../assets/images/appicon.png';
import '../assets/images/logo.svg';
import '../assets/images/alert.svg';
import '../assets/images/gb-logo.svg';

import './polyfills';
// import loadChunks from './utils/load-chunks';

/**
 * Activate to load chunks
 */
// loadChunks();

if ('serviceWorker' in navigator) {
    /**
     * Activate to register the service worker
     */
    // swRuntime.register();
}

import * as contentScroll from './components/content-scroll';

contentScroll.init();
